import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { switchMap, map, take, first } from 'rxjs/operators';
import { Observable, from, of, BehaviorSubject } from "rxjs";
import { FirebaseObject, Statistics } from "../models/global";
import { Ship, OwnedShip } from "../models/ship";
import { AuthService } from "./auth.service";
import { DataService } from "./data.service";
import { MissionType } from "../models/mission";

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    //user: Observable<User>;
    stats: Observable<Statistics>;
    
    
    currentMissionTypeFilter: BehaviorSubject<string>
    missionTypeFilters: string[]

    plop = "pppp"

    constructor(public afs: AngularFirestore, private ds: DataService) {
        this.currentMissionTypeFilter = new BehaviorSubject(null)

        this.missionTypeFilters = Object.keys(MissionType)
    }

    // GLOBAL

    getStats(): Observable<Statistics> {
        return this.ds.objectsWithParams(Statistics).pipe(
            switchMap(sts => {
                if(sts.length == 0) {
                    let ini = new Statistics()
                    return this.ds.saveObject({ ...ini}, Statistics).pipe(
                        switchMap(_ => {
                            return of(ini)
                        })
                    )
                }
                return of(sts[0])
            })
        )
    }
    

}
